.slot-name {
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.slot-count {
  line-height: 20px;
  font-size: 13px;
  height: 20px;
}

.md-shift-template .mbsc-timeline-header-placeholder {
  height: 74px;
}

.md-shift-template .mbsc-timeline-slots {
  height: 41px;
  text-align: center;
}

.md-shift-template .mbsc-timeline-slots {
  text-align: center;
}

.md-shift-template .mbsc-timeline-slot-title {
  padding: 0;
}

.cal-header-nav {
  width: 200px;
}

.cal-header-picker {
  flex: 1 0 auto;
}

.assignee-tag {
  position: absolute;
  right: 12px;
  top: 4px;
  zoom: 0.8;
  z-index: 1;

  nz-tag {
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 5px;
    color: #000000;
  }
}

.md-shift-template .mbsc-timeline-event-slot {
  padding-top: 15px;
  height: 50px;
}
